// @flow
import React, { PureComponent } from 'react';
import Routes from './../../routes';

import { withHelmet } from 'infrastructure/seo/Helmet';
import Partners from 'containers/footer/PartnersContainer';
import CookieContainer from 'containers/footer/CookieContainer';
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import { nullHighlightedPost } from 'types/Settings';
import type { FaviconType, HighLightedPostType } from 'types/Settings';
import ModalContainer from 'containers/fragments/ModalContainer';

import { footerBlacklistPaths } from 'constants/menuConstants';

type HistoryProps = {
  history: any
};

export type StateProps = {
  favicons: FaviconType,
  highlighted_post: HighLightedPostType,
  highlightedPostModalCount: number,
  showHighlightedPostModal: HighLightedPostType,
  titleLigue: string
};

export type DispatchProps = {
  displayModal: (modalObject: string, content?: Object, force?: boolean) => void,
  fetchSettings: () => void,
  fetchMenu: () => void,
  fetchCategories: () => void,
  fetchPartners: () => void,
  increaseHightlighterPostModalCount: () => void,
  keycloakConnect: () => void,
  setShowHighlightedPostModal: (hithlightedPost: HighLightedPostType) => void,
  resetHightlighterPostModalCount: () => void
};

type Props = {
  location: any
} & DispatchProps &
  StateProps &
  HistoryProps;

class App extends PureComponent<Props> {
  HIGHLIGHTED_POST_MAX = 5;

  componentDidMount() {
    const { fetchSettings, fetchMenu, fetchCategories, fetchPartners, keycloakConnect, highlighted_post } = this.props;
    fetchSettings();
    fetchMenu();
    fetchCategories();
    fetchPartners();
    keycloakConnect();

    if (highlighted_post.title !== '' && highlighted_post.more_info_link !== '') {
      this.handleHighlightedPostModal();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const {
      highlighted_post,
      showHighlightedPostModal,
      location: { pathname }
    } = this.props;
    const splited = pathname.split('/');

    // gestion du scroll
    if (splited[1] === 'faq' || splited[1] === 'contact') {
      if (!splited[2]) {
        window.scrollTo(0, 0);
      } else {
        window.scrollTo(0, 250);
      }
    } else if (splited[1] === 'actualites') {
      window.scrollTo(0, 0);
    } else if (!splited[3]) {
      window.scrollTo(0, 0);
    }

    // gestion de la modale d'une actu mise en avant
    if (
      (highlighted_post !== prevProps.highlighted_post &&
        highlighted_post.title !== '' &&
        highlighted_post.more_info_link !== '') ||
      showHighlightedPostModal !== prevProps.showHighlightedPostModal
    ) {
      this.handleHighlightedPostModal();
    }
  }

  handleHighlightedPostModal = () => {
    const {
      displayModal,
      highlighted_post,
      highlightedPostModalCount,
      increaseHightlighterPostModalCount,
      setShowHighlightedPostModal,
      showHighlightedPostModal,
      resetHightlighterPostModalCount
    } = this.props;

    if (highlighted_post === nullHighlightedPost) {
      return;
    }

    if (
      showHighlightedPostModal &&
      highlighted_post &&
      showHighlightedPostModal.title === highlighted_post.title &&
      showHighlightedPostModal.image === highlighted_post.image &&
      showHighlightedPostModal.excerpt === highlighted_post.excerpt &&
      showHighlightedPostModal.more_info_link === highlighted_post.more_info_link
    ) {
      if (highlightedPostModalCount < this.HIGHLIGHTED_POST_MAX) {
        displayModal('HIGHLIGTED_POST', highlighted_post);
        increaseHightlighterPostModalCount();
      }
    } else {
      setShowHighlightedPostModal(highlighted_post);
      resetHightlighterPostModalCount();
    }
  };

  renderFooter() {
    if (footerBlacklistPaths.includes(this.props.location.pathname)) return null;
    return (
      <React.Fragment>
        <Partners />
        <Footer />
      </React.Fragment>
    );
  }

  componentDidCatch(error, info) {
    if (process.env.NODE_ENV === 'production') {
      this.props.history.push('/404');
      window.location.reload();
    }
  }

  render() {
    return (
      <>
        <ModalContainer />
        <Header />

        <Routes />
        <CookieContainer />
        {this.renderFooter()}
      </>
    );
  }
}

// [SEO] Set default meta tags here !
function renderHelmetDefault(Helmet, pageProps) {
  return (
    <Helmet titleTemplate={`%s`} defaultTitle={pageProps.titleLigue ? pageProps.titleLigue : window.location.href}>
      <link rel="shortcut icon" href={pageProps.favicons['mstile-150']} />
      <link rel="apple-touch-icon" sizes="180x180" href={pageProps.favicons['favicon-180']} />
      <link rel="icon" type="image/png" sizes="32x32" href={pageProps.favicons['favicon-32']} />
      <link rel="icon" type="image/png" sizes="16x16" href={pageProps.favicons['favicon-16']} />
      <meta property={'og:site_name'} content={pageProps.titleLigue || 'Compétitions - FFR'} />
    </Helmet>
  );
}

export default withHelmet(App)(renderHelmetDefault);
