// @flow
import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';

import MenuElement from 'components/header/MenuElement';
import Search from 'components/header/Search';
import MenuElementPlus from 'components/header/MenuElementPlus';
import { getConfSso } from 'constants/sso';
import type { MenuElementType } from 'types/MenuElement';
import type { liensRsType } from 'types/Settings';
import { handleTagEvent } from 'utils/tagManagerUtils';

import userAvatar from './placeholder-profil.png';

type StateProps = {
  connectionInProgress: boolean,
  keycloakData: any,
  liens_rs: liensRsType,
  logo: string,
  menuElements: MenuElementType[],
  titleLigue: string
};

type Props = {
  onCloseMenu: Function,
  location: any
} & StateProps;

type ComponentState = {
  menuItems: Array<{ id: number, size: number }>,
  isMobile: boolean,
  hiddenMenuItems: number[],
  menuElements: MenuElement[],
  menuLinkX: number,
  hiddenSubMenu: boolean
};

class Menu extends PureComponent<Props, ComponentState> {
  _menuElements: Array<?MenuElement>;
  _menu: ?HTMLElement;
  _menuRight: ?HTMLElement;
  _menuLink: ?HTMLElement;
  _frameId: string;
  state: ComponentState = {
    menuItems: [],
    isMobile: false,
    hiddenMenuItems: [],
    hiddenSubMenu: false,
    menuElements: [],
    menuLinkX: 0
  };

  static defaultProps = {
    menuElements: []
  };

  constructor(props: Props) {
    super(props);
    this._menuElements = [];
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  componentDidUpdate() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  resetMenuElementRef = (c: ?MenuElement) => {
    this._menuElements = [];
    this.addMenuElementRef(c);
  };

  addMenuElementRef = (c: ?MenuElement) => {
    this._menuElements.push(c);
  };

  isDisplayed = (id: number) => {
    return !this.state.hiddenMenuItems.includes(id);
  };

  hiddenTemporary = () => {
    this.setState({ hiddenSubMenu: true });

    setTimeout(() => {
      this.setState({ hiddenSubMenu: false });
    }, 500);
  };

  updateDimensions() {
    const menu = this._menu;
    const menuRight = this._menuRight;
    const menuLink = this._menuLink;
    if (menu && menuRight && menuLink) {
      const menuLinkBounds = menuLink.getBoundingClientRect();
      const menuLinkX = menuLinkBounds.left;

      if (menuLinkX !== this.state.menuLinkX) this.setState({ menuLinkX });
      /**
       * Largeur complète de la barre de nav
       * barre de nav moins 80px de padding
       */
      let maxWidth = menu.offsetWidth - 80;

      /**
       * Attention au menu plus manuel
       */
      const menus = this.props.menuElements.filter(item => item.parent === 0);
      const menuPlus = menus.find(menu => menu.type === 'custom' && menu.title === 'Plus');
      if (menuPlus) {
        const subMenusPlus = menuPlus.items;
        if (subMenusPlus.length > 0) {
          maxWidth -= 90;
        }
      }

      /**
       * Il faudra prendre en compte la taille du logo
       */
      const width_logo = 210;

      const width_menu_links =
        this._menuElements.reduce((total, item) => (item ? total + item.getSize() : total), 0) +
        this._menuElements.length * 25;

      const width_menu_right = menuRight.offsetWidth;
      const totalWidth = width_menu_links + width_menu_right + width_logo;

      let currentWidth = totalWidth;
      let deleted = [];
      if (maxWidth > 1000) {
        for (let i = this._menuElements.length - 1; i > 0; i--) {
          if (currentWidth > maxWidth) {
            if (this._menuElements[i]) currentWidth -= this._menuElements[i].getSize();
            deleted = [...deleted, i];
          }
        }
        if (this.state.isMobile) this.setState({ isMobile: false });
      } else {
        if (!this.state.isMobile) this.setState({ isMobile: true });
      }

      deleted = deleted.reverse();
      if (
        (deleted.length > 0 || this.state.hiddenMenuItems.length > 0) &&
        JSON.stringify(deleted) !== JSON.stringify(this.state.hiddenMenuItems)
      ) {
        this.setState({ hiddenMenuItems: deleted });
      }
    }
  }

  hiddenAndClose = () => {
    this.hiddenTemporary();
    this.props.onCloseMenu && this.props.onCloseMenu();
  };

  renderSubMenu = (subMenus: Array<Object>): any => {
    return subMenus.map((subMenu, index) => {
      if (subMenu.type === 'custom') {
        const subMenus = subMenu.items ? subMenu.items : [];

        if (subMenu.url === '#') {
          if (subMenus.length > 0) {
            const subMenusRender = [];
            if (subMenus.length > 7) {
              let subMenusSlice = subMenus.slice(0, 6);
              subMenusRender.push(subMenusSlice);
              subMenusSlice = subMenus.slice(7, 13);
              subMenusRender.push(subMenusSlice);
            } else {
              subMenusRender.push(subMenus);
            }
            const classe = this;
            return (
              <li key={index}>
                <span className="menu__category">{subMenu.title}</span>
                {subMenusRender.map(function(subMenus, i) {
                  return (
                    <ul key={i}>
                      <>{classe.renderSubMenu(subMenus)}</>
                    </ul>
                  );
                })}
              </li>
            );
          } else {
            return (
              <li key={index}>
                <span className="menu__category">{subMenu.title}</span>
              </li>
            );
          }
        } else {
          const link = subMenu.url ?? subMenu.slug_complet ?? '#';
          if (!!subMenu.url) {
            return (
              <li key={index}>
                <a href={`${link}`} target="_blank" rel="noreferrer" onClick={() => this.hiddenAndClose()}>
                  {subMenu.title}
                </a>
              </li>
            );
          } else {
            return (
              <li key={index}>
                <Link to={`${link}`} target="_blank" onClick={() => this.hiddenAndClose()}>
                  {subMenu.title}
                </Link>
              </li>
            );
          }
        }
      } else if (subMenu.type === 'post_type') {
        return (
          <li key={index}>
            <Link to={`${subMenu.slug_complet}`} onClick={() => this.hiddenAndClose()}>
              {subMenu.title}
            </Link>
          </li>
        );
      }
      return null;
    });
  };

  renderChidrenMenuElement = (subMenus: Array<Object>) => {
    const { hiddenSubMenu } = this.state;

    if (!hiddenSubMenu) {
      return <>{this.renderSubMenu(subMenus)}</>;
    }

    return undefined;
  };

  renderMenuElement = () => {
    const { menuElements, onCloseMenu } = this.props;
    const menus = menuElements.filter(item => item.parent === 0);
    let indexMenuElements = 0;

    if (!menus) return [];
    let renderedMenu = menus.map((menu, index) => {
      const subMenus = menu.items ? menu.items : [];

      if (menu.type === 'post_type') {
        if (menu.classes === 'bouton-vert') {
          return null;
        }
        const url = this.props.location.pathname;
        if (subMenus.length > 0) {
          let col = false;
          subMenus.forEach(function(subMenu) {
            if (subMenu.items && subMenu.items.length > 0) {
              col = true;
            }
          });
          const menuEl = (
            <MenuElement
              url={`${menu.slug_complet}`}
              title={menu.title}
              titleAll={menu.title}
              isMobile={this.state.isMobile}
              ref={index === 0 ? this.resetMenuElementRef : this.addMenuElementRef}
              id={indexMenuElements}
              isDisplayed={this.isDisplayed}
              menuX={this.state.menuLinkX}
              key={indexMenuElements}
              isActive={url.match(menu.object_slug)}
              col={!!col}
              onCloseMenu={onCloseMenu}
            >
              <>{this.renderChidrenMenuElement(subMenus)}</>
            </MenuElement>
          );
          indexMenuElements++;
          return menuEl;
        } else {
          const menuEl = (
            <MenuElement
              url={`${menu.slug_complet}`}
              title={menu.title}
              titleAll={menu.title}
              isMobile={this.state.isMobile}
              ref={index === 0 ? this.resetMenuElementRef : this.addMenuElementRef}
              id={indexMenuElements}
              isDisplayed={this.isDisplayed}
              menuX={this.state.menuLinkX}
              key={indexMenuElements}
              isActive={url.match(menu.slug_complet)}
              onCloseMenu={onCloseMenu}
            />
          );
          indexMenuElements++;
          return menuEl;
        }
      }
      return null;
    });

    const menuPlus = menus.find(menu => menu.type === 'custom' && menu.title === 'Plus');
    if (menuPlus) {
      const subMenusPlus = menuElements.filter(item => item.parent === menuPlus.id);
      if (subMenusPlus.length > 0) {
        renderedMenu.push(
          <MenuElement
            url={`/${menuPlus.object_slug}`}
            title={menuPlus.title}
            titleAll={menuPlus.title}
            isMobile={this.state.isMobile}
            ref={indexMenuElements === 0 ? this.resetMenuElementRef : this.addMenuElementRef}
            id={indexMenuElements}
            isDisplayed={this.isDisplayed}
            menuX={this.state.menuLinkX}
            key={indexMenuElements + 1}
            onCloseMenu={onCloseMenu}
          >
            <>{this.renderSubMenu(subMenusPlus)}</>
          </MenuElement>
        );
      }
    }
    return renderedMenu;
  };

  render() {
    const {
      connectionInProgress,
      keycloakData: { authenticated, keycloak },
      onCloseMenu,
      logo,
      titleLigue,
      menuElements,
      liens_rs
    } = this.props;

    // PRIVATE ACCOUNT IDENTIFICATION
    const confSso = getConfSso();
    const keycloakLogo =
      !!keycloak && keycloak.tokenParsed && keycloak.tokenParsed.logo ? keycloak.tokenParsed.logo : '';
    const prenom = !!keycloak && keycloak.tokenParsed ? keycloak.tokenParsed.given_name : '';
    const nom = !!keycloak && keycloak.tokenParsed ? keycloak.tokenParsed.family_name : '';

    // MenuElementPlus
    const hiddenMenuElements = this.state.hiddenMenuItems.map(id => {
      return this._menuElements[id];
    });
    const menus = menuElements.filter(item => item.parent === 0);
    const menuPlus = menus.find(menu => menu.type === 'custom' && menu.title === 'Plus');
    if (menuPlus) {
      const subMenusPlus = menuPlus.items;
      if (subMenusPlus.length > 0) {
        subMenusPlus.map(subMenu => {
          return hiddenMenuElements.push(
            <MenuElement
              url={`/${subMenu.object_slug}`}
              title={subMenu.title}
              titleAll={subMenu.title}
              isMobile={this.state.isMobile}
              id={1}
              isDisplayed={this.isDisplayed}
              menuX={this.state.menuLinkX}
              onCloseMenu={onCloseMenu}
            />
          );
        });
      }
    }
    if (hiddenMenuElements.length > 0) {
      const menuPlus = menuElements.find(menu => menu.type === 'custom' && menu.title === 'Plus');
      if (menuPlus) {
        const menuPlusChildren = menuElements.filter(item => item.parent === menuPlus.id);
        menuPlusChildren.forEach((menu, index) => {
          hiddenMenuElements.splice(
            0,
            0,
            <MenuElement
              url={`${menu.slug_complet}`}
              title={menu.title}
              titleAll={menu.title}
              isMobile={this.state.isMobile}
              id={menu.id}
              isDisplayed={this.isDisplayed}
              menuX={this.state.menuLinkX}
              key={index}
              onCloseMenu={onCloseMenu}
            />
          );
        });
      }
    }

    return (
      <nav className="menu" ref={c => (this._menu = c)}>
        {/* Logo - retour sur la home */}
        <Link to="/" title="Retour à la page d'accueil">
          <span style={{ width: 207, height: 58, display: 'inline-block' }}>
            {logo && <img alt="logo" className="menu__logo" src={logo} />}
          </span>
        </Link>

        {/*  PRIVATE ACCOUNT IDENTIFICATION */}
        {// unauthenticated user
        !connectionInProgress && !authenticated ? (
          <a
            to="/"
            className="btn btn--primary menu__login"
            title="Se connecter ou s'inscrire sur le site"
            href={keycloak && keycloak.createLoginUrl({ redirectUri: confSso.urlEspacePrive })}
          >
            {"Se connecter / S'inscrire à mon espace perso"}
          </a>
        ) : (
          // autenticated user
          !connectionInProgress && (
            <div className="menu__user">
              <p className="ft-truncat">
                {keycloakLogo && <img src={keycloakLogo} alt="" style={{ width: '24px', height: '24px' }} />}
                {!keycloakLogo && <img src={userAvatar} alt="" style={{ width: '24px', height: '24px' }} />}
                {`${prenom ?? ''} ${nom ?? ''}`}
              </p>
              <ul>
                <li>
                  <a href={confSso.urlEspacePrive} onClick={onCloseMenu}>
                    <i className="icon icon-account is-inline"></i>
                    Espace personnel
                  </a>
                </li>
                <li>
                  <a href={keycloak.createLogoutUrl()}>
                    <i className="icon icon-logout is-inline"></i>
                    Se déconnecter
                  </a>
                </li>
              </ul>
            </div>
          )
        )}

        {/* Menu */}
        <ul className="menu__link" ref={c => (this._menuLink = c)}>
          {this.renderMenuElement()}

          <MenuElementPlus
            hiddenMenuElements={hiddenMenuElements}
            isMobile={this.state.isMobile}
            id={10}
            isDisplayed={this.isDisplayed}
            menuX={this.state.menuLinkX}
            text="Plus"
          />
        </ul>

        {/* Liens Reseaux Sociaux */}
        <div className="menu__social">
          {liens_rs.fb_lien && (
            <a
              className="link-icon"
              href={liens_rs.fb_lien}
              target="_blank"
              rel="noopener noreferrer"
              title={`Se rendre sur la page Facebook de ${titleLigue} (nouvel onglet)`}
            >
              <i className="icon icon-facebook" />
            </a>
          )}
          {liens_rs.twitter_lien && (
            <a
              className="link-icon"
              href={liens_rs.twitter_lien}
              target="_blank"
              rel="noopener noreferrer"
              title={`Se rendre sur la page Twitter de ${titleLigue} (nouvel onglet)`}
            >
              <i className="icon icon-twitter" />
            </a>
          )}
          {liens_rs.instagram_lien && (
            <a
              className="link-icon"
              href={liens_rs.instagram_lien}
              target="_blank"
              rel="noopener noreferrer"
              title={`Se rendre sur la page Instagram de ${titleLigue} (nouvel onglet)`}
            >
              <i className="icon icon-instagram" />
            </a>
          )}
          {liens_rs.youtube_lien && (
            <a
              className="link-icon"
              href={liens_rs.youtube_lien}
              target="_blank"
              rel="noopener noreferrer"
              title={`Se rendre sur la page Youtube de ${titleLigue} (nouvel onglet)`}
            >
              <i className="icon icon-youtube" />
            </a>
          )}
          {liens_rs.flickr_lien && (
            <a
              className="link-icon"
              href={liens_rs.flickr_lien}
              target="_blank"
              rel="noopener noreferrer"
              title={`Se rendre sur la page Flickr de ${titleLigue} (nouvel onglet)`}
            >
              <i className="icon icon-flickr" />
            </a>
          )}
        </div>

        {/* Lien trouver un club  */}
        <div className="menu__right" ref={c => (this._menuRight = c)}>
          <Link
            to="/trouver-un-club-de-rugby"
            title="Se rendre sur la page de recherche d'un club"
            className="btn btn--primary menu__club"
            onClick={handleTagEvent(
              'recherche geolocalisee',
              'clic trouver un club',
              this.props.location.pathname,
              onCloseMenu
            )}
          >
            <i className="icon icon-place is-inline" />
            <span>Trouver un club</span>
          </Link>

          <Search />
        </div>

        {/* Fermer le menu */}
        <i className="icon icon-close menu__close js-showMenu" role="button" tabIndex={0} onClick={onCloseMenu} />
      </nav>
    );
  }
}

export default withRouter(Menu);
